import React, { useState, useEffect } from "react";
import styled, { createGlobalStyle, keyframes } from "styled-components";

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Times New Roman', serif; /* 기본 폰트 설정 */
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const blinkCursor = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;
`;

const Text = styled.div`
  text-align: center;
  color: #000;
  font-size: 10em;

  @media (max-width: 768px) {
    font-size: 5em; /* 모바일 화면에서 폰트 크기를 조정 */
  }

  @media (max-width: 480px) {
    font-size: 3em; /* 더 작은 화면에서 폰트 크기를 더 조정 */
  }
`;

const Letter = styled.span`
  opacity: 0;
  animation: ${fadeIn} 0.3s ease-out forwards; /* 애니메이션 지속 시간을 0.3s로 설정 */
  animation-delay: ${(props) =>
    props.delay * 0.3}s; /* 각 글자의 딜레이를 0.3s로 설정 */
`;

const Comma = styled.span`
  opacity: 0;
  animation: ${fadeIn} 0.3s ease-out forwards; /* 애니메이션 지속 시간을 0.3s로 설정 */
  animation-delay: ${(props) =>
    props.delay * 0.3}s; /* 콤마의 딜레이를 0.3s로 설정 */
`;

const Cursor = styled.span`
  font-size: 1em;
  opacity: 1;
  animation: ${blinkCursor} 1s step-start infinite;
`;

function App() {
  const fullText = "Junhyuk Jung";
  const [textArray, setTextArray] = useState([]);
  const [showCursor, setShowCursor] = useState(true);
  const [showComma, setShowComma] = useState(false);

  useEffect(() => {
    // Show cursor for 3 seconds before starting text animation (blinks 3 times)
    const cursorTimeout = setTimeout(() => {
      setShowCursor(false);
      let timeoutIds = [];

      fullText.split("").forEach((letter, index) => {
        const timeoutId = setTimeout(() => {
          setTextArray((prev) => [...prev, letter]);
          if (index === fullText.length - 1) {
            setTimeout(() => {
              setShowComma(true);
            }, 300); // Set delay for the comma appearance
          }
        }, index * 300); // Set delay for each letter
        timeoutIds.push(timeoutId);
      });

      return () => {
        timeoutIds.forEach(clearTimeout);
      };
    }, 3000); // Cursor blink duration for 3 seconds

    return () => clearTimeout(cursorTimeout);
  }, [fullText]);

  return (
    <>
      <GlobalStyle />
      <Container>
        <Text>
          {showCursor && <Cursor>|</Cursor>}
          {!showCursor &&
            textArray.map((letter, i) => (
              <Letter key={i} delay={i}>
                {letter}
              </Letter>
            ))}
          {!showCursor && showComma && <Comma delay={fullText.length}>,</Comma>}
        </Text>
      </Container>
    </>
  );
}

export default App;
